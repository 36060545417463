<script>
import get from 'lodash/get';
import RegisterForm from '@school/components/RegisterForm.vue';

export default {
  components: { RegisterForm },
  data: () => ({ buyable: get(window, 'initialState.training', null) || get(window, 'initialState.bundle', null) }),
  computed: {
    store() {
      return this.$store.state.store.data;
    },
  },
  methods: {
    postMessage(data) {
      window.parent.postMessage(data, '*');
    },
  },
  created() {
    document.documentElement.style = 'background:none;min-width:auto';
    document.body.style = 'background:none;min-width:auto';
  },
  mounted() {
    this.$nextTick(() => {
      this.postMessage({ name: 'load' });
    });
  },
};
</script>

<template>
  <div class="ccomp">
    <div class="ccomp_bg" />
    <RegisterForm :isEmbed="true" :buyable="buyable" :hasPaymentPlans="true"
      @close="postMessage({ name: 'close', store, buyable })" @enroll="postMessage({ name: 'enroll', store, buyable })"
      @complete="postMessage({ name: 'complete', store, buyable })" />
  </div>
</template>

<style lang="scss" scoped>
.ccomp {
  &_bg {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.8);
  }

  ::v-deep .modal-background {
    display: none;
  }
}
</style>
